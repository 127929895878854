import { cloneDeep } from "lodash";
import { action, computed, makeObservable, observable, toJS } from "mobx";
import seedrandom from "seedrandom";
import Tokenizer from "wink-tokenizer";

import hue from "../../../uikit/hue";
import { Trigger, MarkupType, EntityTrigger } from "./devlogs";
import { Message, Transition } from "./types";

const phraseTokenizer = new Tokenizer();

export type PhrasePart = {
  text: string;
  start: number;
  end: number;
  entity: string | null;
  tag: string | null;
  hue: number | null;
};

class MarkableMessage {
  private hueCounter = -1;

  sourceTriggers: Trigger[] = [];
  triggers?: Trigger[] = [];
  tokens: PhrasePart[] = [];
  transitions?: Transition[] = [];
  changeContext?: Record<string, number | string | boolean | null> = {};

  voiceSegmentId?: number;
  from: "ai" | "human";
  message: string;
  time: number;
  id: number | string;
  phraseSequenceId?: number;

  constructor(data: Message) {
    this.deserialize(data);
    makeObservable(this, {
      changeContext: observable,
      transitions: observable,
      tokens: observable,
      sourceTriggers: observable,
      triggers: observable,
      deserialize: action,
      markupTrigger: action,
      markupPhrase: action,
      addIntent: action,
      updateTokens: action,
      isMarked: computed,
    });
  }

  get isMarked() {
    return this.triggers?.some((v) => v.mark !== MarkupType.unmark) ?? false;
  }

  get isEdited() {
    if (this.triggers === undefined) {
      return false;
    }
    if (this.sourceTriggers.length !== this.triggers.length) {
      return true;
    }

    return !this.triggers.every((trigger) =>
      trigger.readOnly
        ? true
        : !!this.sourceTriggers.find(
            (sourceTrigger) => sourceTrigger.id === trigger.id && trigger.mark !== MarkupType.exclude
          )
    );
  }

  get hasInteractiveTriggers() {
    return this.triggers?.some((t) => !t.readOnly) ?? false;
  }

  get intents() {
    return this.triggers?.filter((v) => v.type === "intent").map((v) => v.name) ?? false;
  }

  get entities() {
    return this.triggers?.filter((v) => v.type === "entity").map((v) => v.name) ?? false;
  }

  private nextEntityHue() {
    if (this.hueCounter === -1) {
      this.hueCounter = Math.floor(hue.length * seedrandom(this.message)());
      return hue[this.hueCounter];
    }

    if (this.hueCounter == hue.length - 1) {
      this.hueCounter = 0;
      return hue[this.hueCounter];
    }

    this.hueCounter += 1;
    return hue[this.hueCounter];
  }

  deactiveAll() {
    this.triggers?.forEach(
      action((trigger) => {
        if (trigger.readOnly) return;
        trigger.mark = MarkupType.unmark;
      })
    );
  }

  activeAll() {
    this.triggers?.forEach(
      action((trigger) => {
        if (trigger.readOnly) return;
        if (trigger.mark !== MarkupType.unmark) return;
        trigger.mark = MarkupType.include;
      })
    );
  }

  markCorrectPhrase() {
    this.triggers?.forEach(
      action((trigger) => {
        if (trigger.readOnly) return;
        trigger.mark = MarkupType.include;
      })
    );
  }

  generateDataset() {
    const tokens = this.combineTokens();
    const fullPhrase = this.formatPhraseWithTriggers(tokens);
    const dataset = {
      intents: {},
      entities: {},
    };
    if (this.triggers === undefined) 
    {
      return dataset;
    }
    this.triggers.forEach((trigger) => {
      if (trigger.readOnly) return;

      // Push include/exclude intent
      if (trigger.type === "intent") {
        if (dataset.intents[trigger.name] == null) {
          dataset.intents[trigger.name] = {
            excludes: [],
            includes: [],
          };
        }

        if (trigger.mark === MarkupType.exclude) {
          dataset.intents[trigger.name].excludes.push(fullPhrase);
        } else if (trigger.mark === MarkupType.include) {
          dataset.intents[trigger.name].includes.push(fullPhrase);
        }
      }

      // TODO: Check this operations while saving dataset
      if (trigger.type === "entity" && trigger.mark === MarkupType.include) {
        const phrase = this.formatTokensByTrigger(trigger);

        if (!dataset.entities[trigger.name]) {
          dataset.entities[trigger.name] = { values: [], includes: [], excludes: [] };
        }

        if (trigger.entityValue) {
          dataset.entities[trigger.name].values.push({ value: trigger.entityValue, synonyms: [phrase] });
        }
      }

      // Push entities excludes
      if (trigger.type === "entity" && trigger.mark === MarkupType.exclude) {
        if (dataset.entities[trigger.name] == null) {
          dataset.entities[trigger.name] = { values: [], includes: [], excludes: [] };
        }

        const phrase = this.formatTokensByTrigger(trigger);
        dataset.entities[trigger.name].excludes.push(phrase);
      }
    });

    // If we dont have any intent so push phrase to first entity include
    if (Object.keys(dataset.intents).length === 0) {
      const firstEntity = this.tokens.find((token) => token.entity != null);
      if (firstEntity?.entity) {
        if (dataset.entities[firstEntity.entity] == null) {
          dataset.entities[firstEntity.entity] = { includes: [], excludes: [] };
        }

        dataset.entities[firstEntity.entity].includes.push(fullPhrase);
      }
    }

    console.log(dataset);
    return dataset;
  }

  markupPhrase(
    start: number,
    end: number,
    entity: string,
    tag: string | null = null,
    entityValue: string | null = null
  ) {
    if (this.triggers === undefined) {
      return;
    }
    const parts = this.tokens.slice(start, end + 1);
    if (parts.some((v) => v.entity != null)) {
      throw Error("part of text already contains entity");
    }

    const hue = this.nextEntityHue();
    const location = { start: Infinity, end: 0 };
    parts.forEach((part) => {
      location.start = Math.min(location.start, part.start);
      location.end = Math.max(location.end, part.end);
      part.entity = entity;
      part.tag = tag;
      part.hue = hue;
    });

    this.triggers.push({
      type: "entity",
      id: Date.now(),
      mark: MarkupType.include,
      value: entityValue,
      probability: 1,
      name: entity,
      location,
      tag,
      hue,
      entityValue,
    });
  }

  markupTrigger(id: number, mark: MarkupType) {
    if (this.triggers === undefined) 
    {
      return;
    }
    const trigger = this.triggers.find((v) => v.id === id);
    if (!trigger || trigger.readOnly) return null;
    trigger.mark = mark;
    this.updateTokens();
  }

  formatTokensByTrigger({ location: loc }: Trigger) {
    const tokens = this.tokens.filter((token) => loc.start <= token.start && loc.end >= token.end);
    return this.formatTokens(tokens);
  }

  updateTokens() {
    if (this.triggers === undefined) 
      {
        return;
      }
    const entities = this.triggers.filter((v): v is EntityTrigger => v.type === "entity");
    this.tokens.forEach((token) => {
      const entity = entities.find(({ location: loc, mark, readOnly }) => {
        if (readOnly) return false;
        return mark !== MarkupType.exclude && loc.start <= token.start && loc.end >= token.end;
      });

      if (entity == null) {
        token.entity = null;
        token.hue = null;
        token.tag = null;
        return;
      }

      token.entity = entity.name;
      token.hue = entity.hue;
      token.tag = entity.tag;
    });
  }

  deserialize(data: Message) {
    this.id = data.id;
    this.time = data.time;
    this.from = data.from;
    this.message = data.message;
    this.transitions = data.transitions;
    this.changeContext = data.changeContext;
    this.voiceSegmentId = data.voiceSegmentId;

    this.hueCounter = -1;
    this.sourceTriggers = cloneDeep(data.triggers??[]);
    this.triggers = data.triggers;
    this.tokens = [];

    const entities = this.triggers?.filter((v): v is EntityTrigger => v.type === "entity") ?? [];
    entities.forEach((entity) => {
      entity.hue = this.nextEntityHue();
    });

    this.tokens = MarkableMessage.getTokens(this.message);
    this.updateTokens();
  }

  static getTokens(message: string): PhrasePart[] {
    let beforeChars = 0;

    const tokens = phraseTokenizer.tokenize(message);
    const parts = tokens.map<PhrasePart | null>((token) => {
      const matchIndex = message.indexOf(token.value);
      if (matchIndex === -1) return null;

      const index = beforeChars + matchIndex;
      beforeChars = index + token.value.length;
      message = message.slice(matchIndex + token.value.length);

      return {
        text: token.value,
        start: index,
        end: beforeChars,
        exclude: false,
        entity: null,
        hue: null,
        tag: null,
      };
    });

    return parts.filter((v): v is PhrasePart => v != null);
  }

  combineTokens() {
    const tokens: PhrasePart[] = [];
    this.tokens.forEach((token, i, map) => {
      if (i === 0) {
        tokens.push({ ...token });
        return;
      }

      const { entity, tag } = map[i - 1];
      if (entity === token.entity && tag === token.tag) {
        const last = tokens[tokens.length - 1];
        const indent = token.start - last.end;
        last.end = token.end;
        last.text += " ".repeat(indent) + token.text;
        return;
      }

      tokens.push({ ...token });
    });

    return tokens;
  }

  formatTokens(tokens: PhrasePart[]) {
    return tokens.reduce((value, token, i, map) => {
      const indent = i === 0 ? 0 : token.start - map[i - 1].end;
      return value + " ".repeat(indent) + token.text;
    }, "");
  }

  formatPhraseWithTriggers(tokens: PhrasePart[]) {
    let phrase = "";
    tokens.forEach((token, i, map) => {
      const indent = i > 0 ? token.start - map[i - 1].end : token.start;
      phrase += " ".repeat(indent);

      if (token.entity) {
        const entity = token.tag ? `${token.entity}:${token.tag}` : token.entity;
        phrase += `(${token.text})[${entity}]`;
        return;
      }

      phrase += token.text;
    });

    return phrase;
  }

  addIntent(intent: string) {
    this.triggers?.push({
      type: "intent",
      mark: MarkupType.include,
      location: { start: 0, end: 0 },
      id: Date.now(),
      probability: 1,
      name: intent,
    });
  }

  addEntity(entity: string) {
    this.triggers?.push({
      type: "entity",
      mark: MarkupType.include,
      location: {
        start: 0,
        end: 0,
      },
      id: Date.now(),
      probability: 1,
      name: entity,
      tag: null,
      hue: null,
      value: "",
      entityValue: null,
    });
  }

  serialize(): Message {
    return {
      id: this.id,
      from: this.from,
      message: this.message,
      time: this.time,
      transitions: toJS(this.transitions),
      triggers: toJS(this.triggers),
      changeContext: toJS(this.changeContext),
    };
  }
}

export default MarkableMessage;
