import React, { FC, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Trigger } from "../../core/workspace/session-storage/devlogs";
import MarkableMessage from "../../core/workspace/session-storage/MarkableMessage";

import { Icon } from "../../uikit";
import { PureButton } from "../../uikit/Button";
import { Tooltip } from "../../uikit/popup";
import { formatDate } from "../helpers";

import TriggerBadge from "../TriggersEdit/Trigger";
import DevMessage from "../DevMessage";
import * as S from "./styled";
import dayjs from "dayjs";

interface Props {
  onTrigger: (trigger: Trigger) => void;
  onCommit: (message: MarkableMessage) => void;
  onEdit: (message: MarkableMessage) => void;
  message: MarkableMessage;
  editable: boolean;
  selected: boolean;
  currentMessageInAudio?: boolean;

  allowTransition?: boolean;
  allowContext?: boolean;
}

const ClientMessage: FC<Props> = (props) => {
  const {
    message,
    onCommit,
    onEdit,
    selected,
    currentMessageInAudio,
    editable,
    onTrigger,
    allowTransition,
    allowContext,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!selected) return;
    ref.current?.scrollIntoView({ block: "start", behavior: "smooth" });
  }, [selected]);

  useEffect(() => {
    if (!currentMessageInAudio) return;

    ref.current?.scrollIntoView({ block: "start", behavior: "smooth" });
  }, [currentMessageInAudio]);

  let lastIndex = 0;
  const phrases = message.tokens.map((token, i) => {
    const style = token.hue != null ? { "--hue": token.hue } : { color: "#fff" };
    const indent = token.start - lastIndex;
    lastIndex = token.end;

    return (
      <span key={i} style={style}>
        {" ".repeat(indent)}
        {token.text}
      </span>
    );
  });

  return (
    <S.Message ref={ref} isSelected={selected}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {!!currentMessageInAudio && <S.CurrentInAudioMark />}
        <S.MessageBubble isSelected={selected}>
          {editable && (
            <S.Controls>
              {message.isMarked == false && (
                <Tooltip text="Mark as correct" position="top right">
                  <PureButton onClick={() => onCommit(message)}>
                    <Icon name="checkin" />
                  </PureButton>
                </Tooltip>
              )}

              <Tooltip text="Edit phrase triggers" position="top right">
                <PureButton onClick={() => onEdit(message)}>
                  <Icon name="edit" />
                </PureButton>
              </Tooltip>
            </S.Controls>
          )}

          <S.Phrase>{phrases}</S.Phrase>

          {message.triggers?.length > 0 && (
            <S.Triggers>
              {message.triggers.map((trigger, i) => (
                <TriggerBadge
                  key={i}
                  disabled
                  trigger={trigger}
                  phrase={message.message}
                  onClick={() => onTrigger(trigger)}
                />
              ))}
            </S.Triggers>
          )}
        </S.MessageBubble>
      </div>

      <DevMessage message={message} allowTransition={allowTransition} allowContext={allowContext} />
      <S.MessageTime>{dayjs(message.time).format("hh:mm:ss A")}</S.MessageTime>
    </S.Message>
  );
};

export default observer(ClientMessage);
