import { observer } from "mobx-react-lite";
import React, { FC, useState } from "react";
import { IHistoryMessage, TextHistoryMessage } from "@dasha.ai/sdk/web/gpt";
import { Form, Label, TextArea } from "semantic-ui-react";


export const EditTextItem: FC<{ item: TextHistoryMessage; onChange: (item: TextHistoryMessage) => void }> = observer(
    ({ item, onChange }) => {
      return (
        <>
          <Form>
            {(item.thinking || item.text === "") && (
              <Form.Field>
                <Label content="Thinking" />
                <TextArea
                  value={item.thinking}
                  onChange={(e, d) =>
                    onChange({ source: 0, type: "Text", thinking: d.value?.toString() ?? undefined, text: item.text })
                  }
                ></TextArea>
              </Form.Field>
            )}
            {(!item.thinking || item.text !== "") && (
              <Form.Field>
                <Label content="Text" />
                <TextArea
                  value={item.text}
                  onChange={(e, d) =>
                    onChange({ source: 0, type: "Text", thinking: item.thinking, text: d.value?.toString() ?? "" })
                  }
                ></TextArea>
              </Form.Field>
            )}
          </Form>
        </>
      );
    }
  );
  