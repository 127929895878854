import { DevLogMessage } from "@core/workspace/session-storage/devlogs";
import { IHistoryMessage } from "@dasha.ai/sdk/web/gpt";
import { makeAutoObservable } from "mobx";

function transformToHistory(message: DevLogMessage, instanceId: string): IHistoryMessage | undefined {
  if (message.msgId === "GptFunctionCallRequestMessage" && message.InstanceId === instanceId) {
    return {
      type: "call",
      functionCallName: message.FunctionName,
      functionCallArgs: message.Args,
    };
  }

  if (message.msgId === "GptResponseMessage" && message.InstanceId === instanceId) {
    return {
      type: "Text",
      text: message.Message,
      thinking: message.Metadata?.Thinking ?? undefined,
      source: 0,
    };
  }

  return undefined;
}

export class HistoryHolder {
  public Messages: IHistoryMessage[] = [];
  public InitialMessages: IHistoryMessage[] = [];
  constructor(history: IHistoryMessage[]) {
    makeAutoObservable(this);
    this.Messages = [...history];
    this.InitialMessages = [...history];
  }

  public get isDirty(): boolean {
    if (this.Messages.length !== this.InitialMessages.length) {
      return true;
    }
    return JSON.stringify(this.Messages) !== JSON.stringify(this.InitialMessages);
  }

  public static fromLogs(messages: DevLogMessage[], instanceId: string) {
    const result: IHistoryMessage[] = [];
    for (var x of messages) {
      const m = transformToHistory(x, instanceId);
      if (m !== undefined) {
        result.push(m);
      }
    }
    return new HistoryHolder(result);
  }

  public deleteMessage(msg: IHistoryMessage) {
    this.Messages = this.Messages.filter((x) => x!==msg);
  }

  public Replace(msg: IHistoryMessage, newItem: IHistoryMessage) {
    const index = this.Messages.findIndex((x) => x === msg);
    if (index < 0)
    {
      return;
    }
    this.Messages = [...this.Messages.slice(0, index), newItem, ... this.Messages.slice(index + 1) ];
  }

  public CreateAfter(msg: IHistoryMessage | undefined, newItem: IHistoryMessage): IHistoryMessage | undefined {
    if (msg === undefined) {
      this.Messages = [newItem, ...this.Messages];
      return newItem;
    }
    const index = this.Messages.findIndex((x) => x === msg);
    if (index < 0)
    {
      return undefined;
    }
    this.Messages = [...this.Messages.slice(0, index+1), newItem, ... this.Messages.slice(index+1) ];
    return newItem;
  }
}
